@import url(https://fonts.googleapis.com/css2?family=Charmonman&family=Montserrat:wght@200;400;600;800&display=swap);
.pre-heading{
  font-family: 'Charmonman', cursive;
  line-height: 1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img{
  max-width: 100%;
}
body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  color: #4fb948;
  text-decoration: none;
}
.container-full{
  max-width: 100%;
  padding: 0 40px;
}
.container{
  max-width: calc(1180px - 80px);
  padding: 0 40px;
  margin: 0 auto;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
.align-center{
  align-items: center;
}
.col-md-6, .col-sm-6{
  width: 50%;
  flex: 50% 1;
}
.col-md-8{
  width: 66.6%;
  flex: 66.6% 1;
}
.col-md-4{
  width: 30%;
  flex: 30% 1;
}
.justify-center{
  justify-content: center;
}
.text-center{
  text-align: center;
}
.section, section{
  padding: 100px 0;
}
.content-body{
  padding-top: 150px;
}
.btn{
      padding: 14px 40px;
      background-color: #4fb948;
      color: #fff;
      border-radius: 10px;
      margin: 0 auto;
      text-transform: uppercase;
  }
  .section-heading{
    text-align: center;
    margin-bottom: 40px;
  }
  .section-heading h3{
    font-family: 'Charmonman', cursive;
    font-size: 24px;
  }
  .section-heading h2{
    font-family: Montserrat;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #4fb948;
    font-size: 32px;
  }
  .section-heading p{
    text-transform: uppercase;
    font-size: 20px;
  }
.heading{
  font-size: 32px;
  color: #4fb948;
  margin-bottom: 15px;
}
.text-wrapper-left{
  padding-left: 40px;
}
  @media(max-width: 768px){
    .text-wrapper-left{
      padding: 20px 0 0;
    }
    .col-md-6, .col-md-4, .col-md-8{
      width: 100%;
      flex: 100% 1;
    }
    .col-sm-6{
      width: 50%;
      flex: 50% 1;
    }
    .container-full, .container{
      padding: 0 10px;
    }
  }
.header-sec.about{
    background-image: url(/static/media/about.b51f201e.jpg);
}
.quote-section{
    min-height: 400px;
    background-image: url(/static/media/quote.04229703.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}
.hero-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 90vh;
    background-image: url(/static/media/hero.d2acda24.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.hero-sec:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(9 37 7 / 59%);
    left: 0;
    top: 0;
    z-index: 0;
}
.hero-content{
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.hero-content h1{
    font-size: 48px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 20px;
}
.hero-content .pre-heading{
font-size: 34px;
color: #fff;

}


.features{
    background: #EFFCEF;
}
.card{
    background: #fdfffd;
    padding: 60px 40px;
    margin: 0 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    margin-bottom: 20px;
}
.card img{
    margin-bottom: 20px;
}
.card p{
    font-size: 15px;

}
.about img, .delivery img{
    border-radius: 25px;
}
.about .row, .delivery .row{
    align-items: center;
}

.about-text {
    padding: 0 40px 0 0;
}
.about-text p, .delivery-text p{
    margin-bottom: 30px;
    line-height: 1.4;
}
.delivery-text{
    padding: 0 0 0 40px;
}
.delivery{
    background: #000;
}
.delivery .pre-heading, .delivery p{
    color: #fff;
}
.baggage{
    background-image: url(/static/media/baggage.2f127e38.jpg);
    background-size: cover;
    background-position: right;
    position: relative;
    padding: 140px 0;
}
.baggage:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgb(79 185 72 / 90%), transparent);
}
.baggage *{
    z-index: 2;
    color: #fff;
}
.big-heading{
    font-size: 38px;
    font-weight: 500;
}
.big-heading span{
    font-size: 1.2em;
    font-weight: 700;
}
.baggage h3{
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 10px;
}
@media(max-width: 768px){
    .baggage{
        background-position: 80%;
    }
    .baggage:before{
        background:rgb(79 185 72 / 90%);
    }
    .about-text{
        padding: 0 0 40px;
    }
    .delivery-text{
        padding: 40px 0 0;
    }
    .hero-sec{
        min-height: 60vh;
        background-attachment: unset;
    }
    .hero-content .pre-heading{
        font-size: 22px;
    }
    .hero-content h1{
        font-size: 32px;
    }
}
button{
    cursor: pointer;
}
.track-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 300px;
    background-color: #e6f2d7;
}
.track-form form{
    min-width: 33.33%;
}
.track-form label{
    display: block;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 15px;
}
.form-input{
    background: #fff;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    display: flex;
}
.form-input input{
    padding: 15px 30px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 18px;
    color: #333;
}
.form-input input::-webkit-input-placeholder{
    color: #ccc;
}
.form-input input:-ms-input-placeholder{
    color: #ccc;
}
.form-input input::placeholder{
    color: #ccc;
}
.form-input button{
    padding: 10px 40px;
    border: none;
    background: #4fb948;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
}
.track-info{
    background-color: #f4f4f4;
}
.track-status h2{
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
}
.track-status h2 strong{
    font-size: 600;
    color: #4fb948;
}
.track-status h3{
    font-size: 36px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 100px;
}
.historyData{
    margin: 20px;
    background-color: #ccc;
}
.track-bar{
    display: flex;
    position: relative;
}
.track-bar .track-his{
    width: 25%;
    text-align: center;
    position: relative;
}
.track-his:not(:last-child):before {
    content: '';
    left: 50%;
    width: 100%;
    height: 1px;
    background: #c4c4c4;
    position: absolute;
    top: -20px;
}
.track-his:after {
    content: '';
    position: absolute;
    top: -30px;
    height: 20px;
    left: calc(50% - 10px);
    width: 20px;
    background: #c4c4c4;
    border-radius: 100%;
}
.track-his.active:before, .track-his.active:after{
    background-color: #4fb948;
}
.destination{
    text-transform: uppercase;
    position: relative;
    margin-bottom: 50px;
}
.destination:before {
    content: '';
    width: 20%;
    position: absolute;
    height: 2px;
    background: #4fb948;
    top: calc(50% - 1px);
    left: 40%;
}
.product-info {
    background: #f4f4f4;
    margin: 10px;
    padding: 20px 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
}
.product-info p{
    margin-bottom: 10px;
}
@media(max-width: 768px){
    .track-his{
        font-size: 10px;
    }
}
.header-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 500px;
    background-image: url(/static/media/contact-us.e410dff9.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.header-sec:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(9 37 7 / 59%);
    left: 0;
    top: 0;
    z-index: 0;
}
.header-content{
    z-index: 1;
}
.header-content h1{
    font-size: 48px;
    text-transform: uppercase;
    color: #fff;
}
.form {
    padding: 30px 50px;
    border-radius: 25px;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    background-color: #fff;
}
.form h3{
    margin-bottom: 15px;
    text-transform: uppercase;
}
.contact-sec{
    background-color: #f4f4f4;
}
.contact-sec h2{
    text-transform: uppercase;
    margin-bottom: 100px;
    letter-spacing: 1px;
}
.input{
    margin-bottom: 20px;
}

.input label{
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    margin-left: 30px;
    color: #444;
}
.input input, .input textarea{
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px 30px;
    border-radius: 25px;
    outline: none;
    color: #4fb948;
}
.input textarea{
    min-height: 300px;
    resize: vertical;
}
.button button{
    display: block;
    padding: 15px 40px;
    background: #4fb948;
    border: none;
    outline: none;
    border-radius: 25px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 auto;
    min-width: 150px;
    cursor: pointer;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
    color: #d2d2d2;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder{
    color: #d2d2d2;
}
input::placeholder, textarea::placeholder{
    color: #d2d2d2;
}
.contact-info{
    padding: 20px;
}
.contact-details{
    padding: 20px 40px;
    border-radius: 25px;
    border: 1px solid #d2d2d2;
    margin-bottom: 15px;
}
.contact-details i{
    font-size: 40px;
    margin-bottom: 20px;
    color: #d2d2d2;
}
.contact-details h4{
    margin-bottom: 20px;
    text-transform: uppercase;
}
.contact {
    margin-bottom: 15px;
}
.header{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    align-items: center;
    z-index: 9999;
    box-shadow: 0 4px 30px rgb(0 0 0 / 30%);
}
.header .col-md-6{
    flex: 1 1;
    width: 50%;
}
.logo img{
    max-height: 60px;
}
.menu.col-md-6{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.menu ul{
    text-align: right;
}
.menu ul li{
    display: inline-block;
    list-style: none;
    padding: 10px 0;
    margin: 0 10px;
    position: relative;
    transition: 0.2s ease;

}
.menu li:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #4fb948;
    transition: 0.2s ease;
}
.menu li:hover a{
    color: #4fb948;
}
.menu li:hover::before{
    width: 100%;
}
.menu a{
    color: #000;
    font-size: 14px;
}
.menu li:last-child{
    background-color: #4fb948;
    padding: 10px 20px;
}
.menu li:last-child a, .menu li:last-child:hover a{
    color: #fff;
}

.page{
    min-height: 90vh;
}


.burger-menu{
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #4fb948;
    border-radius: 5px;
}

.burger-menu span{
    display: block;
    margin-bottom: 4px;
    height: 2px;
    width: 15px;
    background: #fff;
    

}
.burger-menu span:last-child{
    margin: 0;
}

@media(min-width:767px){
    .burger-menu{
        display: none;
    }
}
@media(max-width:768px){
    .menu ul{
        display: none;
    }
}
.sidebar{
    position: fixed;
    width: 330px;
    max-width: 99%;
    left: -335px;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 999999;
    transition: 0.2s ease;
}
.open-sidebar.sidebar{
    left: 0;
}
.sidebar .menus{
    padding: 80px 0 20px;
}

.sidebar li{
    list-style: none;
    padding: 10px 20px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    transition: 0.2s ease;
}
.sidebar li:hover{
    background-color: #4fb948;
}
.sidebar a:hover{
    color: #fff;
}
.sidebar a{
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
}
.close-btn{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    right:20px;
    background-color: #4fb948;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-btn span{
    width: 60%;
    height: 2px;
    background: #fff;
    position: absolute;
    margin: auto;
}
.close-btn span:first-child{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.close-btn span:last-child{
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
footer{
    padding: 50px 0;
    background-color: #1c1c1c;
    color: #ccc;
}
.footer-logo{
    max-width: 100px;
    margin-bottom: 10px;
}
footer h6{
    font-size: 16px;
    margin-bottom: 10px;
}
footer p{
    font-size: 12px;
}

footer li{
    list-style: none;
    margin-bottom: 5px;
}
footer a{
    color: #ccc;
}
.talk{
    margin-top: 20px;
}
.talk p{
    font-size: 16px;
    margin-bottom: 10px;
}
.credits{
    padding: 20px 0;
    background-color: #010101;
}
.credits p{
    color: #fff;
    font-size: 12px;
}

.text-end{
    text-align: right;
}
@media(max-width: 768px){
    .credits .col-md-6:first-child{
        margin-bottom: 10px;
    }
    .m-text-center{
        text-align: center;
    }
}

.header-sec.about{
    background-image: url('../../assets/about.jpg');
}
.quote-section{
    min-height: 400px;
    background-image: url('../../assets/quote.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}
footer{
    padding: 50px 0;
    background-color: #1c1c1c;
    color: #ccc;
}
.footer-logo{
    max-width: 100px;
    margin-bottom: 10px;
}
footer h6{
    font-size: 16px;
    margin-bottom: 10px;
}
footer p{
    font-size: 12px;
}

footer li{
    list-style: none;
    margin-bottom: 5px;
}
footer a{
    color: #ccc;
}
.talk{
    margin-top: 20px;
}
.talk p{
    font-size: 16px;
    margin-bottom: 10px;
}
.credits{
    padding: 20px 0;
    background-color: #010101;
}
.credits p{
    color: #fff;
    font-size: 12px;
}

.text-end{
    text-align: right;
}
@media(max-width: 768px){
    .credits .col-md-6:first-child{
        margin-bottom: 10px;
    }
    .m-text-center{
        text-align: center;
    }
}
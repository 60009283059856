@import url('https://fonts.googleapis.com/css2?family=Charmonman&family=Montserrat:wght@200;400;600;800&display=swap');
.pre-heading{
  font-family: 'Charmonman', cursive;
  line-height: 1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img{
  max-width: 100%;
}
body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  color: #4fb948;
  text-decoration: none;
}
.container-full{
  max-width: 100%;
  padding: 0 40px;
}
.container{
  max-width: calc(1180px - 80px);
  padding: 0 40px;
  margin: 0 auto;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
.align-center{
  align-items: center;
}
.col-md-6, .col-sm-6{
  width: 50%;
  flex: 50%;
}
.col-md-8{
  width: 66.6%;
  flex: 66.6%;
}
.col-md-4{
  width: 30%;
  flex: 30%;
}
.justify-center{
  justify-content: center;
}
.text-center{
  text-align: center;
}
.section, section{
  padding: 100px 0;
}
.content-body{
  padding-top: 150px;
}
.btn{
      padding: 14px 40px;
      background-color: #4fb948;
      color: #fff;
      border-radius: 10px;
      margin: 0 auto;
      text-transform: uppercase;
  }
  .section-heading{
    text-align: center;
    margin-bottom: 40px;
  }
  .section-heading h3{
    font-family: 'Charmonman', cursive;
    font-size: 24px;
  }
  .section-heading h2{
    font-family: Montserrat;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #4fb948;
    font-size: 32px;
  }
  .section-heading p{
    text-transform: uppercase;
    font-size: 20px;
  }
.heading{
  font-size: 32px;
  color: #4fb948;
  margin-bottom: 15px;
}
.text-wrapper-left{
  padding-left: 40px;
}
  @media(max-width: 768px){
    .text-wrapper-left{
      padding: 20px 0 0;
    }
    .col-md-6, .col-md-4, .col-md-8{
      width: 100%;
      flex: 100%;
    }
    .col-sm-6{
      width: 50%;
      flex: 50%;
    }
    .container-full, .container{
      padding: 0 10px;
    }
  }
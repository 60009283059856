.header-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 500px;
    background-image: url('../../assets/contact-us.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.header-sec:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(9 37 7 / 59%);
    left: 0;
    top: 0;
    z-index: 0;
}
.header-content{
    z-index: 1;
}
.header-content h1{
    font-size: 48px;
    text-transform: uppercase;
    color: #fff;
}
.form {
    padding: 30px 50px;
    border-radius: 25px;
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    background-color: #fff;
}
.form h3{
    margin-bottom: 15px;
    text-transform: uppercase;
}
.contact-sec{
    background-color: #f4f4f4;
}
.contact-sec h2{
    text-transform: uppercase;
    margin-bottom: 100px;
    letter-spacing: 1px;
}
.input{
    margin-bottom: 20px;
}

.input label{
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    margin-left: 30px;
    color: #444;
}
.input input, .input textarea{
    width: 100%;
    border: 1px solid #ccc;
    padding: 15px 30px;
    border-radius: 25px;
    outline: none;
    color: #4fb948;
}
.input textarea{
    min-height: 300px;
    resize: vertical;
}
.button button{
    display: block;
    padding: 15px 40px;
    background: #4fb948;
    border: none;
    outline: none;
    border-radius: 25px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 auto;
    min-width: 150px;
    cursor: pointer;
}
input::placeholder, textarea::placeholder{
    color: #d2d2d2;
}
.contact-info{
    padding: 20px;
}
.contact-details{
    padding: 20px 40px;
    border-radius: 25px;
    border: 1px solid #d2d2d2;
    margin-bottom: 15px;
}
.contact-details i{
    font-size: 40px;
    margin-bottom: 20px;
    color: #d2d2d2;
}
.contact-details h4{
    margin-bottom: 20px;
    text-transform: uppercase;
}
.contact {
    margin-bottom: 15px;
}
.header{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    align-items: center;
    z-index: 9999;
    box-shadow: 0 4px 30px rgb(0 0 0 / 30%);
}
.header .col-md-6{
    flex: 1;
    width: 50%;
}
.logo img{
    max-height: 60px;
}
.menu.col-md-6{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.menu ul{
    text-align: right;
}
.menu ul li{
    display: inline-block;
    list-style: none;
    padding: 10px 0;
    margin: 0 10px;
    position: relative;
    transition: 0.2s ease;

}
.menu li:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #4fb948;
    transition: 0.2s ease;
}
.menu li:hover a{
    color: #4fb948;
}
.menu li:hover::before{
    width: 100%;
}
.menu a{
    color: #000;
    font-size: 14px;
}
.menu li:last-child{
    background-color: #4fb948;
    padding: 10px 20px;
}
.menu li:last-child a, .menu li:last-child:hover a{
    color: #fff;
}

.page{
    min-height: 90vh;
}


.burger-menu{
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #4fb948;
    border-radius: 5px;
}

.burger-menu span{
    display: block;
    margin-bottom: 4px;
    height: 2px;
    width: 15px;
    background: #fff;
    

}
.burger-menu span:last-child{
    margin: 0;
}

@media(min-width:767px){
    .burger-menu{
        display: none;
    }
}
@media(max-width:768px){
    .menu ul{
        display: none;
    }
}
.hero-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 90vh;
    background-image: url('../../assets/hero.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}
.hero-sec:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(9 37 7 / 59%);
    left: 0;
    top: 0;
    z-index: 0;
}
.hero-content{
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.hero-content h1{
    font-size: 48px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 20px;
}
.hero-content .pre-heading{
font-size: 34px;
color: #fff;

}


.features{
    background: #EFFCEF;
}
.card{
    background: #fdfffd;
    padding: 60px 40px;
    margin: 0 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    margin-bottom: 20px;
}
.card img{
    margin-bottom: 20px;
}
.card p{
    font-size: 15px;

}
.about img, .delivery img{
    border-radius: 25px;
}
.about .row, .delivery .row{
    align-items: center;
}

.about-text {
    padding: 0 40px 0 0;
}
.about-text p, .delivery-text p{
    margin-bottom: 30px;
    line-height: 1.4;
}
.delivery-text{
    padding: 0 0 0 40px;
}
.delivery{
    background: #000;
}
.delivery .pre-heading, .delivery p{
    color: #fff;
}
.baggage{
    background-image: url('../../assets/baggage.jpg');
    background-size: cover;
    background-position: right;
    position: relative;
    padding: 140px 0;
}
.baggage:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgb(79 185 72 / 90%), transparent);
}
.baggage *{
    z-index: 2;
    color: #fff;
}
.big-heading{
    font-size: 38px;
    font-weight: 500;
}
.big-heading span{
    font-size: 1.2em;
    font-weight: 700;
}
.baggage h3{
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 10px;
}
@media(max-width: 768px){
    .baggage{
        background-position: 80%;
    }
    .baggage:before{
        background:rgb(79 185 72 / 90%);
    }
    .about-text{
        padding: 0 0 40px;
    }
    .delivery-text{
        padding: 40px 0 0;
    }
    .hero-sec{
        min-height: 60vh;
        background-attachment: unset;
    }
    .hero-content .pre-heading{
        font-size: 22px;
    }
    .hero-content h1{
        font-size: 32px;
    }
}
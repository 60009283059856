.sidebar{
    position: fixed;
    width: 330px;
    max-width: 99%;
    left: -335px;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 999999;
    transition: 0.2s ease;
}
.open-sidebar.sidebar{
    left: 0;
}
.sidebar .menus{
    padding: 80px 0 20px;
}

.sidebar li{
    list-style: none;
    padding: 10px 20px;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    transition: 0.2s ease;
}
.sidebar li:hover{
    background-color: #4fb948;
}
.sidebar a:hover{
    color: #fff;
}
.sidebar a{
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
}
.close-btn{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    right:20px;
    background-color: #4fb948;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-btn span{
    width: 60%;
    height: 2px;
    background: #fff;
    position: absolute;
    margin: auto;
}
.close-btn span:first-child{
    transform: rotate(45deg);
}
.close-btn span:last-child{
    transform: rotate(-45deg);
}
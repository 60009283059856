button{
    cursor: pointer;
}
.track-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 300px;
    background-color: #e6f2d7;
}
.track-form form{
    min-width: 33.33%;
}
.track-form label{
    display: block;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 15px;
}
.form-input{
    background: #fff;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    display: flex;
}
.form-input input{
    padding: 15px 30px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 18px;
    color: #333;
}
.form-input input::placeholder{
    color: #ccc;
}
.form-input button{
    padding: 10px 40px;
    border: none;
    background: #4fb948;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
}
.track-info{
    background-color: #f4f4f4;
}
.track-status h2{
    font-size: 24px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
}
.track-status h2 strong{
    font-size: 600;
    color: #4fb948;
}
.track-status h3{
    font-size: 36px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 100px;
}
.historyData{
    margin: 20px;
    background-color: #ccc;
}
.track-bar{
    display: flex;
    position: relative;
}
.track-bar .track-his{
    width: 25%;
    text-align: center;
    position: relative;
}
.track-his:not(:last-child):before {
    content: '';
    left: 50%;
    width: 100%;
    height: 1px;
    background: #c4c4c4;
    position: absolute;
    top: -20px;
}
.track-his:after {
    content: '';
    position: absolute;
    top: -30px;
    height: 20px;
    left: calc(50% - 10px);
    width: 20px;
    background: #c4c4c4;
    border-radius: 100%;
}
.track-his.active:before, .track-his.active:after{
    background-color: #4fb948;
}
.destination{
    text-transform: uppercase;
    position: relative;
    margin-bottom: 50px;
}
.destination:before {
    content: '';
    width: 20%;
    position: absolute;
    height: 2px;
    background: #4fb948;
    top: calc(50% - 1px);
    left: 40%;
}
.product-info {
    background: #f4f4f4;
    margin: 10px;
    padding: 20px 30px;
    border-radius: 5px;
    border: 1px solid #ddd;
}
.product-info p{
    margin-bottom: 10px;
}
@media(max-width: 768px){
    .track-his{
        font-size: 10px;
    }
}